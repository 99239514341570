<template>
    <div class="box">
        <h2>经理回复消息</h2>
        <div class="form-row">
            <div class="form-item">
                <div class="form-item" style="margin-right: 30px">
                    <span>留言主题</span>
                    <el-select v-model="message" name="message" style="width: 120px" size="small" class="get-method" placeholder="全部">
                        <el-option v-for="item in checkMessage" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                    </el-select>
                </div>
                <span>时间范围</span>
                <el-date-picker v-model="timeArea" size="small" type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"> </el-date-picker>
                <el-button class="search-btn" @click="search"> <img src="~img/alibaba/search.png" />搜索</el-button>
            </div>
        </div>
        <div class="list">
            <div class="item" v-for="(item, index) in list" :key="index">
                <el-image fit="cover" round :src="require('img/alibaba/manager.png')"></el-image>
                <div class="item-inner">
                    <div class="top">
                        经理 <span>{{ $util.timeStampTurnTime(item.create_time) }}</span>
                    </div>
                    <div class="content">
                        {{ item.reply }}
                    </div>
                    <div class="return-msg">
                        <span>回复：</span>{{ item.content }}
                        <!-- <span class="delete" @click="del(item)"><img src="~img/alibaba/del.png" alt=""></span> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="jump" :data="list">
            <el-pagination class="jump-count" style="float: right" :page-size="pageSize" @current-change="handlePageChange" :current-page.sync="currentPage" layout="prev, pager, next, jumper" ref="pagination" :total="total"> </el-pagination>
            <div class="total-number">共{{list.length}}条</div>
        </div>
    </div>
</template>

<script>
import { msgGet, delMsg } from "@/api/member/member"

export default {
    name: "return_message",
    components: {},
    data: () => {
        return {
            // 时间区间
            loading: true,
            list: [],
            total: 0,
            pageSize: 3,
            currentPage: 1,
            total: 0,
            timeArea: [],
            beginTime: "",
            endTime: "",
            message: "all",
            checkMessage: [
                { label: "全部", value: "all" },
                { label: "物流", value: "logistics" },
                { label: "质量", value: "quality" },
                { label: "售后", value: "sale" }
            ]
        }
    },
    created() {
        this.getMsgGet()
    },
    mounted() {
        let self = this
        setTimeout(function () {
            self.yes = false
        }, 300)
    },
    methods: {
        getMsgGet() {
            msgGet({
                begin_time: this.beginTime,
                end_time: this.endTime,
                page: this.currentPage,
                page_size: this.pageSize,
                subject: this.message
            })
                .then((res) => {
                    this.loading = false
                    if (res.code != 0) {
                        throw new Error(res.message)
                    }

                    let { count, list, page_count } = res.data
                    this.list = list
                    this.total = count
                    console.log(this.list)
                })
                .catch((e) => {
                    this.loading = false
                    this.$message(e.message)
                })
        },
        search() {
            console.log(this.timeArea.length)
            if (this.timeArea.length == 0) {
                this.beginTime = "946656000"
                this.endTime = "4070880000"
            } else {
                this.beginTime = this.timeArea[0].getTime() / 1000
                this.endTime = this.timeArea[1].getTime() / 1000
            }
            this.loading = true
            this.getMsgGet()
        },
        handlePageSizeChange(size) {
            this.pageSize = size
            this.refresh()
        },
        handlePageChange() {
            this.getMsgGet()
        },

        // 删除留言
        del(e) {
            console.log(e.message_id)

            delMsg({
                message_id: e.message_id
            })
                .then((res) => {
                    if (res.code == 0) {
                        console.log(res)
                    }
                    this.getMsgGet()
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }
}
</script>
<style lang="scss" scoped>
.box {
    width: 100%;
    position: relative;

    h2 {
        font-size: 24px;
        color: $ns-text-color-black;
        padding-bottom: 5px;
        border-bottom: 1px solid #d9d9d9;

        .balance {
            margin-left: 10px;
            color: red;
            vertical-align: -2px;
        }

        .hints {
            margin-left: 20px;
            font-size: 12px;
            color: $ns-text-color-gray;
            font-weight: 400;
            vertical-align: -1px;
        }
    }

    .form-row {
        display: flex;
        align-items: center;
        margin-top: 30px;

        .form-item {
            display: flex;
            align-items: center;
            color: $ns-text-color-black;

            span {
                margin-right: 10px;
            }

            .search-btn {
                height: 32px;
                width: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: $ns-font-size-base;
                color: #ffffff;
                background-color: $base-color;
                margin-left: 10px;

                img {
                    width: 14px;
                    height: 14px;
                    margin-right: 5px;
                }
            }
        }
    }

    .list {
        padding-top: 30px;

        .item {
            border: 1px solid #f4f4f4;
            box-sizing: border-box;
            padding: 20px;
            margin-bottom: 10px;
            display: flex;
            align-items: flex-start;

            .el-image {
                width: 60px;
                height: 60px;
                border-radius: 50%;
                background-color: #f1f1f1;
                box-sizing: border-box;
                padding: 10px;
            }

            .item-inner {
                width: calc(100% - 80px);
                margin-left: 20px;

                .top {
                    font-size: 20px;
                    color: $ns-text-color-black;

                    span {
                        color: $ns-text-color-gray;
                        font-size: $ns-font-size-base;
                    }
                }

                .content {
                    color: $ns-text-color-gray;
                    font-size: $ns-font-size-base;
                    margin: 10px 0;
                }

                .return-msg {
                    padding: 20px;
                    background-color: #f8f8f8;
                    font-size: $ns-font-size-lg;
                    color: $ns-text-color-black;

                    span {
                        color: $ns-text-color-gray;
                        margin-right: 5px;
                    }
                    :hover {
                        color: #ed6030;
                    }
                }
            }
        }
    }
}
.delete {
    font-size: $ns-font-size-base;
    color: $base-color;
    cursor: pointer;
    float: right;
    img {
        width: 40px;
    }
}
.jump {
    font-size: 13px;
    color: #606266;
    .total-number {
      float:right;
    //   line-height: 16px;
    margin-top: 5px;
    }
}
</style>
